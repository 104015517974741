@tailwind base;

@layer base {
  h1 {
    @apply text-h1;
  }

  h2 {
    @apply text-h2;
  }

  h3 {
    @apply text-h3;
  }

  h4 {
    @apply text-h4;
  }

  h5 {
    @apply text-h5;
  }

  h6 {
    @apply text-h6;
  }
}

@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Avenir";
  src: local("AvenirLTStd"),
    url("./app/assets/fonts/AvenirLTStd-Book.otf") format("opentype");
}

@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');

body {
  font-family: "Avenir", sans-serif;
  color: #1A2429;
}

.pt {
  font-family: "PT Serif", serif;
}

:hover {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 200ms;
    transition-timing-function: linear;
}

/* React Modal */
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 100ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
